import React from 'react';
import { useState } from 'react';
import { MatrixRainingLetters } from "react-mdr";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
const API_PATH = process.env.REACT_APP_API_PATH;


function LoginForm() {
    const [acc,setAcc] = useState('testm');
    const [pw,setPw] = useState('testm');
    const navigate = useNavigate();

    const parseJwt = (token) => {
        try {
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
          return JSON.parse(jsonPayload);
        } catch (e) {
          return null;
        }
    };

    const handleLogin = async () => { 
        try {      
          const response = await axios.post(`${API_PATH}/admin/login`, { Account: acc, Password: pw });  
          console.log(response)
          if (response.status === 200) {
            localStorage.setItem('token', response.data.source); 
            const decodedToken = parseJwt(response.data.source);
            localStorage.setItem('name',  decodedToken.Name); 
            localStorage.setItem('id',  decodedToken.Id); 
            navigate('/index', { replace: true })
          }
        } catch (error) {
            console.error(error)
        }
      };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <MatrixRainingLetters uniqueKey={uuidv4()} custom_class="m-0 p-0" />
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            borderRadius: '15px', // 添加圆角
            padding: '20px', // 添加一些内边距使内容不贴边
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // 添加一些阴影效果
            }}>
            <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5" style={{fontWeight:'bolder'}}>
                    系统登入 v1
                </Typography>
                
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="account"
                    label="帳號"
                    name="account"
                    onChange={(e)=>setAcc(e.target.value)}
                    value={acc}
                    autoFocus
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="密碼"
                    type="password"
                    onChange={(e)=>setPw(e.target.value)}
                    value={pw}
                    id="password"
                    autoComplete="current-password"
                    />
                    <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 5,backgroundColor:'black' }}
                    onClick={handleLogin}
                    >
                    登入
                    </Button>
                </Box>
                </Box>
            </Container>
            </ThemeProvider>
        </div>
    </div>
  );
}

export default LoginForm;
