import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Box, CircularProgress } from '@mui/material';
import AppBarWithHamburger from './AppBarWithHamburger';

const API_PATH = process.env.REACT_APP_API_PATH;


const Applications = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRow, setSelectedRow] = useState({
    id: 0,
    option1: '',
    option2: '',
    option3: '',
    option5: '',
    pipeline: 0,
    point: 0,
    createDate: '',
    status: '',
    memo: '',
    option4:'',
    option6:'',
    timeLimit:0,
    file: null, // 初始化文件数据为空
  });
  
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/admin/logs`);
  
      if (response.status === 200) { 
        setRows(response.data.source);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };
  const deleteApplication = async (id) => {
    try {
      const response = await axios.delete(`${API_PATH}/admin/application`, {
        params: {
          id: id
        }
      }); 
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };
  const handleRowDoubleClick = (params) => {
    setSelectedRow(params.row);
    setOpen(true);
  };


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {

    selectedRow.timeLimit = selectedRow.timeLimit || 0;

    // 创建表单数据
    const formData = new FormData();
    formData.append('id', selectedRow.id);
    formData.append('option1', selectedRow.option1);
    formData.append('option2', selectedRow.option2);
    formData.append('option3', selectedRow.option3);
    formData.append('option5', selectedRow.option5);
    formData.append('pipeline', selectedRow.pipeline);
    formData.append('point', selectedRow.point);
    formData.append('createDate', selectedRow.createDate);
    formData.append('status', selectedRow.status);
    formData.append('timeLimit', selectedRow.timeLimit);
    formData.append('memo', selectedRow.memo);
    formData.append('option4', selectedRow.option4);
    formData.append('option6', selectedRow.option6);

    // 附加文件数据
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    try {
      const response = await axios.put(`${API_PATH}/admin/application`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('提交成功:', response.data);
      alert('提交成功');
      handleClose();
      fetchData();
    } catch (error) {
      console.error('提交失败:', error);
      alert('提交失败，请重试');
    }
  };

  useEffect(() => {
    fetchData();
  }, []); 

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'option1', headerName: '地區', width: 100 },
    { field: 'option2', headerName: '性別', width: 80 },
    { field: 'option3', headerName: '平台類型', width: 120 },
    { field: 'option4', headerName: '活躍度', width: 100 },
    { field: 'option5', headerName: '頭像', width: 120 },
    { field: 'option6', headerName: '蘋果安卓', width: 120 },
    { field: 'pipeline', headerName: '進度條', width: 100 },
    { field: 'timeLimit', headerName: '時間限制', width: 100 },
    { field: 'ageRange', headerName: '範圍', width: 100 },
    { field: 'point', headerName: '點數', width: 100 },
    { field: 'amount', headerName: '數量', width: 100 },
    {
      field: 'uploadFileUrl',
      headerName: '上传文件',
      width: 150,
      renderCell: (params) => (
        params.value ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.value, '_blank')}
          >
            下载
          </Button>
        ) : (
          '尚未上传文件'
        )
      ),
    },
    {
      field: 'downloadFileUrl',
      headerName: '下载文件',
      width: 150,
      renderCell: (params) => (
        params.value ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(params.value, '_blank')}
          >
            下载
          </Button>
        ) : (
          '尚未下载文件'
        )
      ),
    },    
    { field: 'createDate', headerName: '提交時間', width: 120 },
    { field: 'status', headerName: '狀態', width: 80 },
    { field: 'memo', headerName: '備註', width: 100 },
    {
      field: 'Operation',
      headerName: '操作',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton aria-label="reset" onClick={() => deleteApplication(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    },
  ];
  if(loading) {
    return (
      <>
        <Box sx={{ height: 800, width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <CircularProgress />
        </Box>      
      </>
    )
  }

  return (
    <>
        <AppBarWithHamburger />
        <Box sx={{ height: 800, width: '90%', margin: 10 }} >
            <Typography variant="h3" gutterBottom style={{display:'flex',justifyContent:'center'}}>
                资料列表
            </Typography>
            <Button variant="contained" endIcon={<SendIcon />} style={{marginBottom:20}} onClick={handleClickOpen}>
                上传
            </Button>
            <DataGrid rows={rows} columns={columns} pageSize={50} rowsPerPageOptions={[5]}   onRowDoubleClick={handleRowDoubleClick}/>
            
        </Box>

        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                  <DialogTitle>修改资料</DialogTitle>
                      <DialogContent>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          mt: 4,
                        }}
                      >
                        <TextField
                          label="地區"
                          value={selectedRow.option1}
                          onChange={(e) => handleInputChange('option1', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="性別"
                          value={selectedRow.option2}
                          onChange={(e) => handleInputChange('option2', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="平台類型"
                          value={selectedRow.option3}
                          onChange={(e) => handleInputChange('option3', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="活躍度"
                          value={selectedRow.option4}
                          onChange={(e) => handleInputChange('option4', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="頭像"
                          value={selectedRow.option5}
                          onChange={(e) => handleInputChange('option5', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="蘋果安卓"
                          value={selectedRow.option6}
                          onChange={(e) => handleInputChange('option6', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="时间限制"
                          value={selectedRow.timeLimit ?? 0}
                          onChange={(e) => handleInputChange('timeLimit', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="进度条"
                          value={selectedRow.pipeline}
                          onChange={(e) => handleInputChange('pipeline', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="点数"
                          value={selectedRow.point}
                          onChange={(e) => handleInputChange('point', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="提交时间"
                          value={selectedRow.createDate}
                          onChange={(e) => handleInputChange('createDate', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="状态"
                          value={selectedRow.status}
                          onChange={(e) => handleInputChange('status', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        <TextField
                          label="备注"
                          value={selectedRow.memo}
                          onChange={(e) => handleInputChange('memo', e.target.value)}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />

                        {/* 文件上传部分 */}
                        <Typography variant="h6" component="div" gutterBottom sx={{ marginTop: 3 }}>
                          文件上传
                        </Typography>
                        <Input
                          type="file"
                          onChange={handleFileChange}
                          inputProps={{
                            accept:
                              'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          }}
                          sx={{ marginBottom: 2 }} // 添加底部间隔
                        />
                        {selectedFile && (
                          <Typography variant="body1" component="div" sx={{ mt: 2 }}>
                            已选择文件: {selectedFile.name}
                          </Typography>
                        )}
                      </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button type="button" onClick={handleSubmit}>更新</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

    </>
  );
};

export default Applications;
