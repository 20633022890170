import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Container } from '@mui/material';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const API_PATH = process.env.REACT_APP_API_PATH;

const AppBarWithHamburger = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pwOpen, setpwOpen] = React.useState(false);
  const [pw, setpw] = React.useState('');
  const [safePw, setSafePw] = React.useState('');
  const [npw, setnpw] = React.useState('');
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if(event.target.innerText === '会员资料') {
        navigate('/index', { replace: true });
      }

      if(event.target.innerText === '申请资料') {
        navigate('/applications', { replace: true });
      }

      if(event.target.innerText === '地區') {
        navigate('/option1', { replace: true });
      }
      if(event.target.innerText === '性別') {
        navigate('/option2', { replace: true });
      }      
      
      if(event.target.innerText === '平台類型') {
        navigate('/option3', { replace: true });
      }      
      
      if(event.target.innerText === '活躍度') {
        navigate('/option4', { replace: true });
      }      
      
      if(event.target.innerText === '頭像') {
        navigate('/option5', { replace: true });
      }

      if(event.target.innerText === '蘋果安卓') {
        navigate('/option6', { replace: true });
      }

      if(event.target.innerText === '年紀範圍') {
        navigate('/age', { replace: true });
      }

      if(event.target.innerText === '登出') {
        localStorage.clear();
        navigate('/login', { replace: true });
      }

      if(event.target.innerText === '修改密碼') {
        handlePwClickOpen();
      }

      if(event.target.innerText === '設置保險箱') {
        handleClickOpen();
      }

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSubmit = async () => {
    // 构建请求体
    const requestBody = {
      SafePassword: pw,
      Password: npw,
    };
  
    try {
      const response = await axios.put(`${API_PATH}/admin/adminpw`, requestBody);
      if(response.status === 200) {
        alert('更新成功')
        handlePwClose();
      }   
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSafeSubmit = async () => {
    // 构建请求体
    const requestBody = {
      SafePw: safePw,
    };
  
    try {
      const response = await axios.post(`${API_PATH}/admin/safe`, requestBody);
      if(response.status === 200) {
        alert('更新成功')
        handleClose();
      }   
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePwClickOpen = () => {
    setpwOpen(true);
  };

  const handlePwClose = () => {
    setpwOpen(false);
  };

  const menuItems = ['登出','設置保險箱','修改密碼', '会员资料', '申请资料','年紀範圍','地區','性別','平台類型','活躍度','頭像','蘋果安卓'];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor:'black' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2}}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Typography variant="h5" gutterBottom sx={{marginLeft:5,marginTop:2}}>
            {localStorage.getItem('name')}
        </Typography>
        <List sx={{ width: 250 }}>
          {menuItems.map((text) => (
            <ListItem button key={text} onClick={toggleDrawer(false)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <React.Fragment>
                <Dialog
                    open={pwOpen}
                    onClose={handlePwClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    更改密码
                    </DialogTitle>
                    <DialogContent>

                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="保險箱密碼"
                    type="password"
                    onChange={(e)=>setpw(e.target.value)}
                    value={pw}
                    id="password"
                    autoComplete="current-password"
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="新密码"
                    type="password"
                    onChange={(e)=>setnpw(e.target.value)}
                    value={npw}
                    id="password"
                    autoComplete="current-password"
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleSubmit} autoFocus>
                    送出
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    設置保險箱密碼 一生僅能設定一次
                    </DialogTitle>
                    <DialogContent>

                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="保險箱密碼"
                    type="password"
                    onChange={(e)=>setSafePw(e.target.value)}
                    value={safePw}
                    id="password"
                    autoComplete="current-password"
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleSafeSubmit} autoFocus>
                    送出
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
    </>
  );
};

export default AppBarWithHamburger;