import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Box, CircularProgress } from '@mui/material';
import AppBarWithHamburger from './AppBarWithHamburger';

const API_PATH = process.env.REACT_APP_API_PATH;

const Option2 = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [viewValue, setViewValue] = useState('');
  const [realValue, setRealValue] = useState(0);
  const [selectedRow, setSelectedRow] = useState({
    id: 0,
    viewValue: '',
    realValue: 0,
  });
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/admin/Option2`);
  
      if (response.status === 200) { 
        setRows(response.data.source);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const createOption2 = async () => {
    const request = {
      Id:0,
      ViewValue:viewValue,
      RealValue:realValue
    }
    try {
      const response = await axios.post(`${API_PATH}/admin/Option2`, request);
      if (response.status === 200) { 
        setRows(response.data.source);
        setOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const deleteOption2 = async (id) => {
    try {
      const response = await axios.delete(`${API_PATH}/admin/Option2`, {
        params: {
          id: id
        }
      }); 
      if (response.status === 200) {
        setRows(response.data.source);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };
  const handleViewValueChange = (event) => {
    setViewValue(event.target.value);
  };
  const handleRealValueChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setRealValue(value);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const handleEditOption2Change = (field, value) => {
    if (field === 'realValue') {
      // 使用正则表达式检查输入是否为数字或带小数点的数字
      const isValidNumber = /^-?\d*(\.\d+)?$/.test(value);
      if (!isValidNumber) {
        // 如果输入不是有效的数字，直接返回，不更新状态
        return;
      }
    }
  
    // 如果输入有效或字段不是'realValue'，更新状态
    setSelectedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  
  const handleRowDoubleClick = (params) => {
    setSelectedRow(params.row);
    setUpdateOpen(true);
  };

  const handleEditOption2 = async () => {
    try {
      // 发送 PUT 请求到后端
      const response = await axios.post(
        `${API_PATH}/admin/Option2`,
        {
          Id:selectedRow.id,
          ViewValue: selectedRow.viewValue,
          RealValue: selectedRow.realValue,
        }
      );

      console.log('更新成功:', response.data);
      setRows(response.data.source);
      alert('更新成功');
      setUpdateOpen(false);
    } catch (error) {
      console.error('更新失败:', error);
      alert('更新失败，请重试');
    }
  };

  

  useEffect(() => {
    fetchData();
  }, []); 

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'viewValue', headerName: '显示', width: 200 },
    { field: 'realValue', headerName: '价值', width: 200 },
    {
      field: 'Operation',
      headerName: '操作',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton aria-label="reset" onClick={() => deleteOption2(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    },
  ];
  if(loading) {
    return (
      <>
        <Box sx={{ height: 800, width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <CircularProgress />
        </Box>      
      </>
    )
  }

  return (
    <>
        <AppBarWithHamburger />
        <Container>
        <Box sx={{ height: 800, width: '100%', marginTop: 10 }} >
            <Typography variant="h3" gutterBottom style={{display:'flex',justifyContent:'center'}}>
                性別 選項2列表
            </Typography>
            <Button variant="contained" endIcon={<SendIcon />} style={{marginBottom:20}} onClick={handleClickOpen}>
                上传
            </Button>
            <DataGrid rows={rows} columns={columns} pageSize={50} rowsPerPageOptions={[5]} onRowDoubleClick={handleRowDoubleClick}/>
            
        </Box>
        </Container>

        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>新增选项</DialogTitle>
                <DialogContent>
                  <TextField
                        required
                        margin="dense"
                        id="name"
                        label="选项顯示"
                        fullWidth
                        variant="standard"
                        value={viewValue}
                        onChange={handleViewValueChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="价值"
                        fullWidth
                        variant="standard"
                        value={realValue}
                        onChange={handleRealValueChange}
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button type="button" onClick={createOption2}>送出</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

        <React.Fragment>
        <Dialog open={updateOpen} onClose={handleUpdateClose}>
          <DialogTitle>编辑选项</DialogTitle>
          <DialogContent>
            <TextField
              required
              margin="dense"
              id="name"
              label="选项顯示"
              fullWidth
              variant="standard"
              value={selectedRow.viewValue}
              onChange={(e) => handleEditOption2Change('viewValue', e.target.value)}
            />
            <TextField
              required
              margin="dense"
              id="account"
              label="价值"
              fullWidth
              variant="standard"
              value={selectedRow.realValue}
              onChange={(e) => handleEditOption2Change('realValue', e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdateClose}>取消</Button>
            <Button type="button" onClick={handleEditOption2}>
              保存
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>

    </>
  );
};

export default Option2;
